.IngredientItem {
    display: flex;
    justify-content: space-between;
    padding: 0.1rem 0.5rem;
    border: solid 1px #FFFFFF30;
    font-size: 0.8rem;
    background-color: #112728;
    height: 100%;
}

.IngredientItem_active {
    border: solid 1px #9f5922;
}

.IngredientItem_orderActive:hover {
    cursor: pointer;
    opacity: 0.5;
}


@media screen and (min-width: 850px) {
 
.IngredientItem {
    font-size: 1rem;
}

}
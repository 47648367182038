.Footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-top: solid 1px #FFFFFF30;
    padding: 1rem 0.5rem;
    background-color: #070b0b;
    z-index: 2;
}

.Footer_text {
    font-size: 0.9rem;
    color: #FFFFFF;
}

.Footer_link {
    color: #FFFFFF;
}


.CreateRecipeVideoSection {
    display: flex;
    flex-direction: column;
    z-index: 2;
}

.CreateRecipeVideoSection_header {
    font-size: 1.2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.CreateRecipeVideoSection_titleInputContainer {
    display: flex;
    gap: 0.5rem;
}

.CreateRecipeVideoSection_titleInput {
    border: solid 1px #FFFFFF30;
    background-color: #112728;
    color: #FFFFFF;
    font-size: 1rem;
    max-width: 250px;
}

.CreateRecipeVideoSection_iframe {
    width: 100%;
    height: auto;
    max-width: 1300px;
    aspect-ratio: 16 / 9;
    border: solid 1px #FFFFFF30;
    margin: auto;
}

@media screen and (min-width: 850px) {
    
.CreateRecipeVideoSection_titleInputContainer {
    margin: 0 0 0.5rem 0;
    border-bottom: solid 1px #FFFFFF30;
}

}

@media screen and (min-width: 500px) {
    .CreateRecipeVideoSection_titleInput {
        max-width: none;
    }
}
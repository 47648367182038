.Navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px #FFFFFF30;
    padding: 0.5rem;
    z-index: 2;
    background-color: #070b0b;
    box-shadow: 0 7px 14px 0 #00000030, 0 3px 6px 0 #00000080;
}

.Navbar_logo, .Navbar_logo > h1 {
    display: flex;
    color: #FFFFFF;
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 1.5rem;
    text-decoration: none;
}

.Navbar_logo:hover {
    cursor: pointer;
    opacity: 0.3;
}

.Navbar_image {
    width: 8rem;
    height: auto;
}

.Navbar_navLinks {
    list-style: none;
    display: none;
    gap: 2rem;
    margin: 0;
    padding: 0;
}

.Navbar_navButton {
    color: #FFFFFF;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem;
    border-radius: 5px;
    text-decoration: none;
}

.Navbar_navButton:hover {
    cursor: pointer;
    opacity: 0.5;
}

.Navbar_active {
    background-color: #3D5455;
}

.Navbar_active:hover {
    cursor: default !important;
    opacity: 1 !important;
}

.Navbar_svgIcon {
    fill: #FFFFFF;
    width: 1rem;
    height: auto;
}

.Navbar_buttonsContainer {
    display: flex;
    justify-content: flex-end;
    column-gap: 1rem;
}

.Navbar_button {
    font-size: 0.9rem;
    border: solid 1px #FFFFFF30;
    border-radius: 5px;
    padding: 0.2rem 1rem;
    background-color: #9f5922;
    color: #FFFFFF;
    text-decoration: none;
}

.Navbar_button:hover {
    cursor: pointer;
    opacity: 0.5;
}

@media screen and (min-width: 850px) {

.Navbar_logo {
    font-size: 2rem;
}

.Navbar_image {
    width: 10rem;
}

.Navbar_button {
    font-size: 1rem
}

.Navbar_navLinks {
    display: flex;
}

}
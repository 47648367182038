.EditRecipeActions {
    display: none;
}

.EditRecipeActions_buttonsContainer {
    display: flex;
    gap: 2rem;
    place-content: center;
    width: 100%;
    text-align: center;
    backdrop-filter: blur(10px);
    padding: 1rem 0;
    border-top: solid 1px #FFFFFF30;
    border-bottom: solid 1px #FFFFFF30;
}

.EditRecipeActions_addButton,
.EditRecipeActions_createButton {
    font-size: 1rem;
    border: solid 1px #FFFFFF30;
    background-color: #9f5922;
    color: #FFFFFF;
    width: 100%;
    padding: 0.5rem 1rem;
}

.EditRecipeActions_addButton:hover,
.EditRecipeActions_createButton:hover {
    cursor: pointer;
    border: solid 1px #FFFFFF;
}

.EditRecipeActions_addButtonsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
}

.EditRecipeActions_editButtonsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem
}

@media screen and (min-width: 850px) {

.EditRecipeActions {
    display: block;
}

}

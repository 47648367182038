.InstructionsSection {
    display: grid;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
}

.InstructionsSection_hidden {
    display: none;
}

@media screen and (min-width: 850px) {

.InstructionsSection_hidden {
    display: grid
}
    
}


.RecipeFooter {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    z-index: 2;
}

.RecipeFooter_button {
    background-color: #9f5922;
    color: #FFFFFF;
    border: solid 1px #FFFFFF30;
    padding: 1rem 0;
}

.RecipeFooter_button:hover {
    cursor: pointer;
}

.RecipeFooter_active {
    border: solid 1px #FFFFFF;
    text-decoration: underline;
}

@media screen and (min-width: 850px) {

.RecipeFooter {
    display: none;
}

}
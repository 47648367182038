.ProfilePage {
    display: grid;
    min-height: 100vh;
    min-height: 100svh;
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    background-color: #070b0b;
    background-size: cover;
    background-position: center;
}

.ProfilePage_overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #070b0b;
    opacity: 0.5;
}
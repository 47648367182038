.SampleRecipeBookPage {
    display: grid;
    grid-template-rows: auto 1fr;
    min-height: 100dvh;
    overflow: hidden;
    background-color: #070b0b;
    color: #FFFFFF;
}

.SampleRecipeBookPage_overlay{
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100dvw;
    min-height: 100dvh;
    background-color: #070b0b;
    opacity: 0.5;
}

.SampleRecipeBookPage_sampleRecipeListContainer {
    min-height: 100dvh;
    display: grid;
    grid-template-rows: auto 1fr auto;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}
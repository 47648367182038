.CreateRecipeActions {
    display: none;
}

.CreateRecipeActions_buttonsContainer {
    display: flex;
    gap: 2rem;
    place-content: center;
    width: 100%;
    text-align: center;
    backdrop-filter: blur(10px);
    padding: 1rem 0;
    border-top: solid 1px #FFFFFF30;
    border-bottom: solid 1px #FFFFFF30;
}

.CreateRecipeActions_addButton,
.CreateRecipeActions_createButton {
    font-size: 1rem;
    border: solid 1px #FFFFFF30;
    background-color: #9f5922;
    color: #FFFFFF;
    width: 100%;
    padding: 0.5rem 1rem;
}

.CreateRecipeActions_addButton:hover,
.CreateRecipeActions_createButton:hover {
    cursor: pointer;
    border: solid 1px #FFFFFF;
}

.CreateRecipeActions_addButtonsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
}

.CreateRecipeActions_editButtonsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem
}


@media screen and (min-width: 850px) {
    
.CreateRecipeActions {
    display: block;
}

}
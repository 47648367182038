.Profile { 
    display: grid;
    justify-content: center;
    padding: 1rem;
    overflow: hidden;
}


.Profile_formContainer {
    display: grid;
    height: fit-content;
    padding: 1rem;
    gap: 0.5rem;
    color: #FFFFFF;
    z-index: 2;
    border: solid 1px #FFFFFF30;
    background-color: #11272890;
    backdrop-filter: blur(10px); 

}

.Profile_text {
    display: flex;
    gap: 0.5rem;
}

.Profile_buttonsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
    text-align: center;
}

.Profile_delete {
    font-size: 1rem;
    background-color: #112728;
    color: #FFFFFF;
    border: solid 1px #FFFFFF30;
}

.Profile_delete:hover {
    cursor: pointer;
    border: solid 1px #FFFFFF;
}

.Profile_confirmText {
    text-align: center;
}
.SampleRecipeOption {
    position: relative;
    border: solid 2px #FFFFFF30;
    border-radius: 5px;
    padding-bottom: 4rem;
    box-shadow: 0 7px 14px 0 #00000030, 0 3px 6px 0 #00000080;
}

.SampleRecipeOption:hover {
    cursor: pointer;
    border: solid 2px #FFFFFF;
}

.SampleRecipeOption_thumbnail {
    width: 100%;
    border-radius: 5px;
}

.SampleRecipeOption_textOverlay {
    display: grid;
    gap: 0.2rem;
    padding: 0.5rem 1rem;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: #112728;
    border-top: solid 1px #FFFFFF30;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.SampleRecipeOption_title,
.SampleRecipeOption_text {
    color: #FFFFFF;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.SampleRecipeOption_title {
    font-size: 1rem;
    border-bottom: solid 1px #FFFFFF30;
    padding-bottom: 0.5rem;
}

.SampleRecipeOption_text {
    font-size: 0.8rem;
}
.RecipeActions {
    display: none;
}

.RecipeActions_container {
    display: grid;
    gap: 1rem;
    place-content: center;
    width: 100%;
    text-align: center;
    backdrop-filter: blur(10px);
    padding: 1rem 0;
    border-top: solid 1px #FFFFFF30;
    border-bottom: solid 1px #FFFFFF30;
}

.RecipeActions_textContainer {
    display: grid;
    gap: 1rem;
}

.RecipeActions_orange {
    color: #9f5922;
    font-weight: bold;
}

.RecipeActions_button {
    font-size: 1rem;
    border: solid 1px #FFFFFF30;
    background-color: #9f5922;
    color: #FFFFFF;
    width: 100%;
    padding: 0.5rem 1rem;
}

.RecipeActions_button:hover {
    cursor: pointer;
    border: solid 1px #FFFFFF;
}

.RecipeActions_buttonsContainer {
    display: flex;
    place-content: center;
    gap: 1rem;
}

.RecipeActions_leftButtonsContainer,
.RecipeActions_rightButtonsContainer {
    display: grid;
    gap: 0.5rem;
    grid-template-columns: 1fr 1fr;
}


@media screen and (min-width: 850px) {

.RecipeActions {
    display: block;
}

.RecipeActions_hideView {
    display: none;
}

}

@media screen and (max-height: 700px) {

.RecipeActions_container {
    gap: 0.2rem;
}

.RecipeActions_textContainer {
    gap: 0.2rem;
}

.RecipeActions_text {
    font-size: 0.8rem;
}

.RecipeActions_button {
    font-size: 0.8rem;
}

}
.Recipe {
    display: grid;
    grid-template-rows: auto 1fr auto;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    gap: 0.5rem;
    transition: 0.5s;
}

.Recipe_main {
    z-index: 2;
}

@media screen and (min-width: 850px) {

    .Recipe {
        grid-template-columns: 1fr 400px;
        grid-template-rows: auto 1fr;
        padding: 0.5rem;
    }

    .Recipe_hideView {
        grid-template-columns: auto 1fr !important;
    transition: 0.5s;
}
    
    .Recipe_largeView {
        grid-template-columns: 1fr 400px !important;
        transition: 0.5s;
    }
    
    .Recipe_main {
        display: grid;
        grid-template-rows: 1fr 1fr;
        gap: 0.5rem;
        grid-row: 1/3;
        grid-column: 2/3;
    }
    
}
    
@media screen and (min-width: 1250px) {
    
    .Recipe {
        grid-template-columns: 1fr 800px;
    }
    
    .Recipe_main {
        display: grid;
        grid-template-rows: 1fr 1fr;
        gap: 0.5rem;
        grid-row: 1/3;
        grid-column: 2/3;
    }
    
}



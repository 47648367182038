.IngredientsTable {
    display: grid;
    grid-template-rows: auto 1fr auto;
    gap: 0.5rem;
}

.IngredientsTable_header {
    font-size: 1rem;
    font-weight: 400;
    border-bottom: solid 1px #FFFFFF30;
}

.IngredientsTable_table {
    display: grid;
    grid-template-columns: 1fr;
    gap: .5rem;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
}

.IngredientsTable_itemContainer {
    height: 100%;
}

.IngredientsTable_itemContainer:hover {
    cursor: pointer;
    border: solid 1px #FFFFFF;
}

.IngredientsTable_button {
    border: solid 1px #FFFFFF30;
    color: #FFFFFF;
    background-color: #9f5922;
    padding: 0 0.2rem;
}

.IngredientsTable_button:hover {
    cursor: pointer;
    border: solid 1px #FFFFFF;
}

.IngredientsTable_addButton {
    font-size: 1rem;
    border: solid 1px #FFFFFF30;
    background-color: #9f5922;
    color: #FFFFFF;
    width: 100%;
}

.IngredientsTable_resultsText {
    font-size: 0.8rem;
}

.IngredientsTable_pageButtonsContainer {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.IngredientsTable_pageButton {
    fill: #FFFFFF;
    height: 1.5rem;
    width: auto;
}

.IngredientsTable_pageButton:hover {
    cursor: pointer;
    opacity: 0.5;
}

@media screen and (min-width: 850px) {
.IngredientsTable_table_hideView {
    grid-template-columns: 1fr 1fr !important;
    grid-auto-flow: column;
}

.IngredientsTable_table_largeView {
    grid-template-columns: 1fr !important;
    grid-auto-flow: column;
}
}

@media screen and (min-width: 1250px) { 

.IngredientsTable_header {
    font-size: 1.2rem;
}

.IngredientsTable_table {
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: column;
}

.IngredientsTable_table_hideView {
    grid-template-columns: repeat(3, 1fr) !important;
}


}




.EditItemForm {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    padding: 0.5rem;
}

.EditItemForm_header {
    font-size: 1rem;
    font-weight: 400;
}

.EditItemForm_nameInput,
.EditItemForm_amountInput {
    border: solid 1px #FFFFFF30;
    background-color: #112728;
    color: #FFFFFF;
    font-size: 1rem;
    padding: 0.5rem;
}

.EditItemForm_buttonsContainer {
    display: none;
}

.EditItemForm_button {
    font-size: 1rem;
    border: solid 1px #FFFFFF30;
    background-color: #9f5922;
    color: #FFFFFF;
    width: 100%;
    padding: 0.5rem 1rem;
}

.EditItemForm_button:hover {
    cursor: pointer;
    border: solid 1px #FFFFFF;
}
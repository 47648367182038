.InstructionItem {
    display: grid;
    grid-template-columns: auto 2fr 6fr 2fr;
    column-gap: 0.5rem;
    text-align: center;
    border: solid 1px #FFFFFF30;
    height: 100%;
    max-height: 100%;
    background-color: #112728;
}

.InstructionItem:hover {
    cursor: pointer;
    border: solid 1px #9f5922;
}

.InstructionItem_action,
.InstructionItem_items,
.InstructionItem_time {
    font-size: 0.9rem;
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2; 
    -webkit-box-orient: vertical;
}

.InstructionItem_number {
    font-size: 1.5rem;
    border: solid 1px #FFFFFF30;
    height: min-content;
    width: 2rem;
    transform: translate(-0.2rem, -0.2rem);
    background: #3D5455;
}

.InstructionItem_items {
    max-height: 2.1rem;
}
.CreateInstructionsTable {
    display: grid;
    gap: 0.5rem;
    padding: 0 0.5rem;
}

.CreateInstructionsTable_header {
    font-size: 1rem;
    font-weight: 400;
    border-bottom: solid 1px #FFFFFF30;
}

.CreateInstructionsTable_table {
    display: grid;
    gap: .5rem;
}

.CreateInstructionsTable_addStepButton {
    font-size: 1rem;
    border: solid 1px #FFFFFF30;
    background-color: #9f5922;
    color: #FFFFFF;
    width: 100%;
}

.CreateInstructionsTable_addStepButton:hover {
    cursor: pointer;
    border: solid 1px #FFFFFF;
}

.CreateInstructionsTable_pageButtonsContainer {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.CreateInstructionsTable_pageButton {
    fill: #FFFFFF;
    height: 1.5rem;
    width: auto;
}

.CreateInstructionsTable_pageButton:hover {
    cursor: pointer;
    opacity: 0.5;

}

.CreateInstructionsTable_resultsText {
    font-size: 0.8rem;
    color:#FFFFFF;
}


@media screen and (min-width: 850px) {

.CreateInstructionsTable_header {
    font-size: 1.2rem;
}

.CreateInstructionsTable_addStepButton {
    display: none !important;
}
    
}

@media screen and (min-width: 1250px) { 

.CreateInstructionsTable_table {
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: column;
}

}


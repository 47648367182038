.InstructionsTable {
    display: grid;
    grid-template-rows: auto 1fr auto;
    gap: 0.5rem;
    padding: 0 0.5rem;
}

.InstructionsTable_header {
    font-size: 1rem;
    font-weight: 400;
    border-bottom: solid 1px #FFFFFF30;
}

.InstructionsTable_table {
    display: grid;
    gap: .5rem;
}

.InstructionsTable_addStepButton {
    font-size: 1rem;
    border: solid 1px #FFFFFF30;
    background-color: #9f5922;
    color: #FFFFFF;
    width: 100%;
}

.InstructionsTable_addStepButton:hover {
    cursor: pointer;
    border: solid 1px #FFFFFF;
}

.InstructionsTable_pageButtonsContainer {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.InstructionsTable_pageButton {
    fill: #FFFFFF;
    height: 1.5rem;
    width: auto;
}

.InstructionsTable_pageButton:hover {
    cursor: pointer;
    opacity: 0.5;

}

.InstructionsTable_resultsText {
    font-size: 0.8rem;
    color:#FFFFFF;
}


@media screen and (min-width: 850px) {

.InstructionsTable_table_hideView {
    grid-template-columns: 1fr 1fr !important;
    grid-auto-flow: column;
}

.InstructionsTable_table_largeView {
    grid-template-columns: 1fr !important;
    grid-auto-flow: column;
}

.InstructionsTable_header {
    font-size: 1.2rem;
}

.InstructionsTable_addStepButton {
    display: none !important;
}
    
}

@media screen and (min-width: 1250px) { 

.InstructionsTable_table {
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: column;
}

.InstructionsTable_table_hideView {
    grid-template-columns: repeat(3, 1fr) !important;
}

}


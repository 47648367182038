.RecipeList {
    display: grid;
    grid-template-rows: auto 1fr;
    gap: 0.5rem;
    overflow: hidden;
    padding: 0.5rem 1rem;
}

.RecipeList_searchContainer {
    display: flex;
    width: 100%;
    justify-self: center;
    align-items: center;
    z-index: 2;
    border: solid 1px #FFFFFF30;
    border-radius: 5px;
    background-color: #070b0b90;
    backdrop-filter: blur(10px);
    box-shadow: 0 7px 14px 0 #00000030, 0 3px 6px 0 #00000080;
}

.RecipeList_searchText {
    color: #FFFFFF;
    white-space: nowrap;
    padding: 0.5rem;
    border-right: solid 1px #FFFFFF30;
}

.RecipeList_search {
    border: none;
    background-color: #112728;
    color: #FFFFFF;
    font-size: 1rem;
    border: solid 1px #FFFFFF30;
    width: 100%;
    height: 100%;
}

.RecipeList_searchButton {
    padding: 0.5rem;
}

.RecipeList_searchButton:hover {
    opacity: 0.5;
}

.RecipeList_filtersSidebar {
    display: none;
}

.RecipeList_searchIcon {
    aspect-ratio: 1/1;
    width: 1rem;
    height: auto;
    fill: #FFFFFF;
}

.RecipeList_filterSidebar {
    display: none;
}

.RecipeList_recipesContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    height: min-content;
    justify-content: center;
    width: fit-content;
    justify-self: center;
}

.RecipeList_text {
    text-align: center;
    border: solid 1px #FFFFFF30;
    background-color: #070b0b90;
    backdrop-filter: blur(10px);
    padding: 0.5rem;
    margin: 0 0.5rem;
    height: min-content;
}

@media screen and (min-width: 850px) {
    .RecipeList {
        grid-template-columns: auto 1fr;
    }
    .RecipeList_searchContainer {
        grid-column: 1/3;
    }
    .RecipeList_searchText {
        padding: 0.5rem 2rem;
    }
    .RecipeList_searchButton {
        padding: 0.5rem 1rem;
    }
    .RecipeList_filtersSidebar {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        background-color: #070b0b90;
        backdrop-filter: blur(10px);
        padding: 1rem 1.5rem;
        border: solid 1px #FFFFFF30;
        border-radius: 5px;
        height: fit-content;
        box-shadow: 0 7px 14px 0 #00000030, 0 3px 6px 0 #00000080;
    }
    .RecipeList_filterContainer {
        display: grid;
        gap: 0.5rem;
    }
    .RecipeList_vegetableSelect,
    .RecipeList_carbSelect,
    .RecipeList_proteinSelect {
        background-color: transparent;
        color: #FFFFFF;
        padding: 0.2rem 0.5rem;
        border: solid 1px #FFFFFF30;
        border-radius: 5px;
    }
    .RecipeList_option{
        background-color: #070b0b90;
    }

    .RecipeList_recipesContainer {
        display: grid;
        grid-template-columns: 1fr 1fr;
        flex-wrap: inherit;
        justify-content: start;
        width: 100%;
    }
}

@media screen and (min-width: 1150px) {
    .RecipeList_recipesContainer {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (min-width: 1450px) {
    .RecipeList_recipesContainer {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media screen and (min-width: 1750px) {
    .RecipeList_recipesContainer {
        grid-template-columns: repeat(5, 1fr);
    }
}

@media screen and (min-width: 2050px) {
    .RecipeList_recipesContainer {
        grid-template-columns: repeat(5, 1fr);
    }
}

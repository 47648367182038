.AuthFooter {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    z-index: 3;
}

.AuthFooter_button {
    background-color: #9f5922;
    color: #FFFFFF;
    border: solid 1px #FFFFFF30;
    padding: 1rem 0;
    text-decoration: none;
    text-align: center;
}

.AuthFooter_button:hover {
    cursor: pointer;
    border: solid 1px #FFFFFF;
}

.AuthFooter_active {
    border: solid 1px #FFFFFF;
}

.AuthFooter_active > p {
    text-decoration: underline;
}

.AuthFooter_svgIcon {
    fill: #FFFFFF;
    width: 1.5rem;
    height: auto;
}

@media screen and (min-width: 850px) {
    .AuthFooter {
        /* width: 600px;
        margin: 0 auto;
        place-self: center;
        padding: 1rem; */
        display: none;
    }
}

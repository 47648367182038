.VideoSearchForm {
    display: flex;
    width: fit-content;
    justify-self: center;
    gap: 0.5rem;
    margin-top: 0.5rem;
    z-index: 2;
    border: solid 1px #FFFFFF30;
    background-color: #070b0b90;
    backdrop-filter: blur(10px);
    padding: 0.5rem;
}

.VideoSearchForm_input {
    border: none;
    background-color: #112728;
    color: #FFFFFF;
    font-size: 1rem;
    border: solid 1px #FFFFFF30;
    padding: 0 0.5rem;
    max-width: 200px;
}

.VideoSearchForm_submit {
    border: solid 1px #FFFFFF30;
    background-color: #112728;
    font-size: 1rem;
}

.VideoSearchForm_submit:hover {
    cursor: pointer;
    border: solid 1px #FFFFFF;
}

@media screen and (min-width: 500px) {
    .VideoSearchForm_input {
        max-width: none;
    }
}


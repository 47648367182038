.EditRecipeNavbar {
    display: flex;
    justify-content: space-between;
    padding: 0.3rem;
    padding-bottom: 0;
}

.EditRecipeNavbar_returnButton {
    display: flex;
    align-items: center;
    gap: 0.3rem;
    color: #FFFFFF;
}

.EditRecipeNavbar_returnButton:hover {
    cursor: pointer;
    opacity: 0.5;
}

.EditRecipeNavbar_svg {
    fill: #FFFFFF;
    height: 1rem;
    width: auto;
}

.EditRecipeNavbar_createButton {
    font-size: 0.8rem;
    border: solid 1px #FFFFFF30;
    background-color: #9f5922;
    color: #FFFFFF;
    padding: 0.1rem 0.2rem;
}

.EditRecipeNavbar > .Loading {
    font-size: 0.8rem;
}

.EditRecipeNavbar > .Loading > svg {
    width: 1.3rem;
}

@media screen and (min-width: 850px) {
    .EditRecipeNavbar_createButton {
        display: none !important;
    }
}

.VideoSection {
    display: flex;
    flex-direction: column;
    z-index: 2;
}

.VideoSection_topContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
    width: 100%;
}

.VideoSection_header {
    font-size: 1.2rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.VideoSection_buttonsContainer {
    display: flex;
    gap: 0.5rem;
}

.VideoSection_plusIcon,
.VideoSection_minusIcon {
    fill: #FFFFFF;
    height: 1.2rem;
    width: auto;
}

.VideoSection_plusIcon {
    display: none;
}

.VideoSection_plusIcon_hideView {
    display: block;
}

.VideoSection_minusIcon_hideView {
    display: none;
}

.VideoSection_plusIcon:hover,
.VideoSection_minusIcon:hover {
    cursor: pointer;
    opacity: 0.5;
}

.VideoSection_disabled {
    display: none;
}


.VideoSection_iframe {
    width: 100%;
    height: auto;
    max-width: 1300px;
    aspect-ratio: 16 / 9;
    border: solid 1px #FFFFFF30;
    margin: auto;
}

.VideoSection_iframe_hideView {
    display: none;
}

@media screen and (min-width: 850px) {
    
.VideoSection_topContainer {
    margin: 0 0 0.5rem 0;
    border-bottom: solid 1px #FFFFFF30;
}

.VideoSection_plusIcon_largeView {
    display: none !important;
}

.VideoSection_header_hideView {
    display: none;
}

}

@media screen and (min-width: 1250px) {
.VideoSection_plusIcon {
    display: block;
}
}
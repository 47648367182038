.AddStepForm {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    padding: 0.5rem;
    overflow: hidden;
}

.AddStepForm_header {
    font-size: 1rem;
    height: 1rem;
    font-weight: 400;
}

.AddStepForm_summaryContainer {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 0.5rem;
    align-items: center;
}

.AddStepForm_text {
    font-size: 0.9rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.AddStepForm_actionInput,
.AddStepForm_itemInput,
.AddStepForm_timeInput,
.AddStepForm_descriptionInput,
.AddStepForm_ingredientNameInput,
.AddStepForm_ingredientAmountInput {
    border: solid 1px #FFFFFF30;
    background-color: #112728;
    color: #FFFFFF;
    width: 100%;
    font-size: 0.9rem;
    padding: 0.2rem;
    display: block;
}

.AddStepForm_itemContainer {
    display: grid;
    grid-template-columns: 4fr 1fr;
    gap: 0.5rem;
}

.AddStepForm_addIngredientButton {
    white-space: nowrap;
    border: solid 1px #FFFFFF30;
    color: #FFFFFF;
    background-color: #112728;
    padding: 0 0.2rem;
}

.AddStepForm_textAreaContainer {
    position: relative;
}

.AddStepForm_charLimit {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 0.8rem;
}

.AddStepForm_descriptionInput {
    height: 4rem;
    resize: none;
}

.AddStepForm_ingredientInputContainer {
    display: grid;
    gap: 0.5rem;
    grid-template-columns: 5fr 3fr 2fr;
}

.AddStepForm_ingredientsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.AddStepForm_buttonsContainer {
    display: none;
}

.AddStepForm_button {
    font-size: 1rem;
    border: solid 1px #FFFFFF30;
    background-color: #9f5922;
    color: #FFFFFF;
    width: 100%;
    padding: 0.5rem 1rem;
}

.AddStepForm_button:hover {
    cursor: pointer;
    border: solid 1px #FFFFFF;
}
.RecipeBookPage {
    display: grid;
    min-height: 100vh;
    min-height: 100svh;
    background-color: #070b0b;
    color: #FFFFFF;
}

.RecipeBookPage_recipeContainer {
    display: grid;
    grid-template-rows: auto 1fr;
}

.RecipeBookPage_recipeListContainer {
    display: grid;
    grid-template-rows: auto 1fr auto;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding-bottom: 8rem;
}

.RecipeBookPage_overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #070b0b;
    opacity: 0.5;
}

@media screen and (min-width: 850px) {
    .RecipeBookPage_recipeListContainer {
        padding-bottom: 1rem;
    }
}
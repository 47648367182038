.LoginForm {
    display: grid;
    justify-content: center;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    padding: 4rem 1rem;
}

.LoginForm_form {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    row-gap: 1rem;
    color: #FFFFFF;
    z-index: 2;
    border: solid 1px #FFFFFF30;
    border-radius: 5px;
    background-color: #070b0b90;
    backdrop-filter: blur(10px);
    box-shadow: 0 7px 14px 0 #00000030, 0 3px 6px 0 #00000080;
    height: min-content;
}

.LoginForm_header {
    font-size: 2rem;
}

.LoginForm_inputContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
}
.LoginForm_buttonsContainer {
    padding-top: 1rem;
    display: flex;
}

.LoginForm_input {
    border: solid 1px #FFFFFF30;
    border-radius: 5px;
    background-color: #112728;
    color: #FFFFFF;
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 0.5rem 1rem;
    width: 100%;
}

.LoginForm_button {
    font-size: 1rem;
    line-height: 1.5rem;
    border: solid 1px #FFFFFF30;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    background-color: #9f5922;
    color: #FFFFFF;
    width: 100%;
}

.LoginForm_button:hover {
    cursor: pointer;
    border: solid 1px #FFFFFF;
}

.LoginForm_error {
    text-align: center;
    max-width: 200px;
    justify-self: center;
}

@media screen and (min-width: 800px) {
    .LoginForm_form {
        width: 540px;
        padding: 3rem;
    }
}

@media screen and (min-width: 500px) {
    .LoginForm_input {
        max-width: none
    }
}

.InstructionDetails {
    display: grid;
    grid-template-rows: auto auto 1fr 1fr;
    row-gap: .5rem;
    padding: 0 0.5rem;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
}

.InstructionDetails_header {
    font-size: 1rem;
    font-weight: 400;
    border-bottom: solid 1px #FFFFFF30;
}

.InstructionDetails_topRow {
    display: flex;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    gap: 0.5rem
}

.InstructionDetails_returnIcon,
.InstructionDetails_svgIcon {
    width: 1rem;
    height: auto;
    fill: #FFFFFF;
    margin-right: 0.5rem;
}

.InstructionDetails_returnIcon {
    width: 0.6rem;
}

.InstructionDetails_returnIcon {
    margin-right: 0.2rem;
}

.InstructionDetails_carrot {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
}

.InstructionDetails_summaryContainer,
.InstructionDetails_timeContainer {
    display: flex;
    gap: 0.5rem;
}

.InstructionDetails_summaryContainer {
    overflow: hidden;
    width: 100%;
}

.InstructionDetails_descriptionContainer,
.InstructionDetails_ingredientsContainer {
    display: grid;
}

.InstructionDetails_itemsContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.InstructionDetails_summary,
.InstructionDetails_time,
.InstructionDetails_description,
.InstructionDetails_itemsContainer {
    border: solid 1px #FFFFFF30;
    padding: 0.5rem;
    background-color: #112728;
}

.InstructionDetails_summary,
.InstructionDetails_time,
.InstructionDetails_description {
    font-size: 0.8rem;
}

.InstructionDetails_summary {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.InstructionDetails_item {
    font-size: 0.8rem;
    display: flex;
    justify-content: space-between;
}

.InstructionDetails_item:first-of-type {
    padding-left: 1.5rem;
}


.InstructionDetails_pageButtonsContainer {
    display: flex;
    justify-content: space-between;
}

.InstructionDetails_editDeleteContainer {
    display: flex;
    gap: 0.5rem;
}

.InstructionDetails_editButton,
.InstructionDetails_deleteButton {
    border: solid 1px #FFFFFF;
    color: #FFFFFF;
    padding: 0 0.2rem;
}

.InstructionDetails_editButton:hover,
.InstructionDetails_deleteButton:hover {
    color: #9f5922;
    border: solid 1px #9f5922;
}

.InstructionDetails_pageButton {
    display: flex;
    align-items: center;
    color: #FFFFFF;
}

.InstructionDetails_pageButton:hover {
    opacity: 0.5;
}


@media screen and (min-width: 850px) {

.InstructionDetails_header {
    font-size: 1.2rem
}
 
.InstructionDetails_summary,
.InstructionDetails_time,
.InstructionDetails_description,
.InstructionDetails_item {
    font-size: 1rem;
}

}
.EditStepForm {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    padding: 0.5rem;
    overflow: hidden;
}

.EditStepForm_header {
    font-size: 1rem;
    height: 1rem;
    font-weight: 400;
}

.EditStepForm_summaryContainer {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 0.5rem;
    align-items: center;
}

.EditStepForm_text {
    font-size: 0.9rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.EditStepForm_actionInput,
.EditStepForm_itemInput,
.EditStepForm_timeInput,
.EditStepForm_descriptionInput,
.EditStepForm_ingredientNameInput,
.EditStepForm_ingredientAmountInput {
    border: solid 1px #FFFFFF30;
    background-color: #112728;
    color: #FFFFFF;
    width: 100%;
    font-size: 0.9rem;
    padding: 0.2rem;
    display: block;
}

.EditStepForm_itemContainer {
    display: grid;
    grid-template-columns: 4fr 1fr;
    gap: 0.5rem;
}

.EditStepForm_addIngredientButton {
    white-space: nowrap;
    border: solid 1px #FFFFFF30;
    color: #FFFFFF;
    background-color: #112728;
    padding: 0 0.2rem;
}

.EditStepForm_textAreaContainer {
    position: relative;
}

.EditStepForm_charLimit {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 0.8rem;
}

.EditStepForm_descriptionInput {
    height: 4rem;
    resize: none;
}

.EditStepForm_ingredientInputContainer {
    display: grid;
    gap: 0.5rem;
    grid-template-columns: 5fr 3fr 2fr;
}

.EditStepForm_ingredientsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
}

.EditStepForm_buttonsContainer {
    display: none;
}

.EditStepForm_button {
    font-size: 1rem;
    border: solid 1px #FFFFFF30;
    background-color: #9f5922;
    color: #FFFFFF;
    width: 100%;
    padding: 0.5rem 1rem;
}

.EditStepForm_button:hover {
    cursor: pointer;
    border: solid 1px #FFFFFF;
}
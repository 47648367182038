.VideoSearch {
    display: grid;
    grid-template-rows: auto 1fr;
    overflow: hidden;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.VideoSearch_main {
    display: grid;
    grid-template-rows: auto 1fr;
    gap: 0.5rem;
    padding-bottom: 8rem;
}

.VideoSearch_overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #070b0b;
    opacity: 0.5;
}

.VideoSearch_resultsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    z-index: 2;
    justify-content: center;
    justify-self: center;
}

.VideoSearch_text {
    text-align: center;
    border: solid 1px #FFFFFF30;
    background-color: #070b0b90;
    backdrop-filter: blur(10px);
    padding: 0.5rem;
    margin: 0 0.5rem;
    height: min-content;
}

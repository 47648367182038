.RecipePage {
    display: grid;
    min-height: 100vh;
    min-height: 100svh;
    background-color: #070b0b;
    color: #FFFFFF;
}

.RecipePage_recipeContainer {
    display: grid;
    grid-template-rows: auto 1fr;
}

.SampleRecipe {
    display: grid;
    grid-template-rows: auto 1fr auto;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    gap: 0.5rem;
    transition: 0.5s;
}

.SampleRecipe_main {
    z-index: 2;
}

@media screen and (min-width: 850px) {

.SampleRecipe {
    grid-template-columns: 1fr 400px;
    grid-template-rows: auto 1fr;
    padding: 0.5rem;
}

.SampleRecipe_hideView {
    grid-template-columns: auto 1fr !important;
    transition: 0.5s;
}

.SampleRecipe_largeView {
    grid-template-columns: 1fr 400px !important;
    transition: 0.5s;
}

.SampleRecipe_main {
    display: grid;
    grid-template-rows: 1fr 1fr;
    gap: 0.5rem;
    grid-row: 1/3;
    grid-column: 2/3;
}

}

@media screen and (min-width: 1250px) {

.SampleRecipe {
    grid-template-columns: 1fr 800px;
}

.SampleRecipe_main {
    display: grid;
    grid-template-rows: 1fr 1fr;
    gap: 0.5rem;
    grid-row: 1/3;
    grid-column: 2/3;
}

}

@media screen and (min-width: 1650px) {
    .SampleRecipe_miniView {
        grid-template-columns: 1fr 1200px;
    }
}


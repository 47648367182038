*,
*::before,
*::after {
    box-sizing: border-box;
}

body, h1, h2, p, h3, button, input, input::placeholder, textarea, textarea::placeholder {
    font-family: 'Poppins', sans-serif;
}

body, h1, h2, p, h3 {
    margin: 0;
}

button {
    background-color: transparent;
    border: none;
    padding: 0;
}

button:hover {
    cursor: pointer;
}

input, input::placeholder,
textarea, textarea::placeholder {
    color:#FFFFFF;
}

input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
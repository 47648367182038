.IngredientsSection {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
    row-gap: .5rem;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    padding: 0 0.5rem;
}

.IngredientsSection_hidden {
    display: none
}

.IngredientsSection_header {
    font-size: 1rem;
    font-weight: 400;
    border-bottom: solid 1px #FFFFFF30;
}

.IngredientsSection_table {
    display: grid;
    grid-template-columns: 1fr;
    gap: .5rem;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
}

.IngredientsSection_orderButtonsContainer {
    display: flex;
    place-content: center;
    align-items: center;
    justify-content: space-around;
    border-top: solid 1px #FFFFFF30;
    padding-top: 0.5rem;
}

.IngredientsSection_leftButtonsContainer,
.IngredientsSection_rightButtonsContainer {
    display: flex;
    gap: 0.5rem;
}

.IngredientsSection_button {
    border: solid 1px #FFFFFF30;
    padding: 0.2rem 0.5rem;
    color: #FFFFFF;
    background-color: #9f5922;
}

.IngredientsSection_button:hover {
    cursor: pointer;
    border: solid 1px #FFFFFF;
}

@media screen and (min-width: 850px) {

.IngredientsSection_hidden {
    display: grid
}

.IngredientsSection_button{
    font-size: 1rem;
}

.IngredientsSection_orderButtonsContainer {
    display: none;
}

}


